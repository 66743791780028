import {Col, Form, Row} from "react-bootstrap";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import React, {Fragment} from "react";
import {Input} from "../../../../../_metronic/_partials/controls";
import v from "voca";
import {ImageHolder, VideoHolderViewer} from "../../partials/ImageHolder";
import {ContentCategoriesSelection} from "./ContentCategoriesSelection";

export const fields = <Row className="inputs-rows mt-5">
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="PTBP.COMMON.TITLE"
                defaultMessage="Titolo"
            />}
            component={({field, form, ...props}) => <Fragment>
                <Input form={form} field={field} onChange={e => {

                    form.setFieldValue("slug", v.slugify(e.target.value))

                    return field.onChange(e);
                }} {...props}/>
                <small>
                    <div>Slug: <strong>{form.values.slug}</strong></div>
                </small>
            </Fragment>}
            name="title"
            key="title"
        />
    </Col>
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="PTBP.COMMON.DESCRIPTION"
                defaultMessage="Descrizione"
            />}
            component="textarea"
            name="description"
            key="description"
            className="form-control"
        />
    </Col>
    <Col xs={12}>
        <Field name="category">
            {
                ({field,form}) => {
                    return <ContentCategoriesSelection
                        value={field?.value}
                        handleChange={val => form.setFieldValue(field.name, val)}
                    />
                }
            }
        </Field>
    </Col>
    <Col xs={12}>
        <Field name="media">
            {
                ({field, form}) => {
                    return <ImageHolder
                        type="video"
                        viewer={VideoHolderViewer}
                        imageSrc={field?.value?.src}
                        handleImage={val => form.setFieldValue(field.name, val)}
                    />;
                }
            }
        </Field>
    </Col>
</Row>


