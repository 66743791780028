import {Col, Row} from "react-bootstrap";
import {MustBeAdmin, MustHaveRules, RULES} from "../../../../../../_the_law/_helpers/MustHaveRules";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../../../_metronic/_partials/controls";
import React from "react";
import {SupervisorSelect} from "../../partials/SupervisorSelect";
import {SwitchField} from "../../partials/Filter/fileds/Switch";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../../_metronic/_helpers";


export const fields = <Row className="inputs-rows mt-5">
    <MustHaveRules rules={[RULES.ADMIN, RULES.SCHOOL]}>
        <Col md={12}>
            <SupervisorSelect/>
        </Col>
    </MustHaveRules>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.COMMON.EMAIL"
                defaultMessage="Email"
            />}
            component={Input}
            name="email"
            key="email"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.STUDENT.NICKNAME"
                defaultMessage="Nickname"
            />}
            component={Input}
            name="nickname"
            key="nickname"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.STUDENT.FIRST_NAME"
                defaultMessage="Nome"
            />}
            component={Input}
            name="student.first_name"
            key="student.first_name"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.STUDENT.LAST_NAME"
                defaultMessage="Cognome"
            />}
            component={Input}
            name="student.last_name"
            key="student.last_name"
        />
    </Col>
    <Col md={6}>
        <label>Data di nascita</label>
        <br/>
        <Field name="student.birth_date">
            {
                props => <input value={props.field.value} type="date" className="form-control" onChange={(e) => {
                    props.form.setFieldValue(props.field.name, e.target.value)
                }}/>
            }
        </Field>
    </Col>
    <Col md={6}>
        <label>Genere</label>
        <br/>
        <SwitchField
            options={[
                {
                    label: <span><SVG style={{width: 20}} src={toAbsoluteUrl("/media/icons/male-kid.svg")}/> M</span>,
                    value: "m",
                    onSelected: "#3699FF"
                },
                {
                    label: <span><SVG style={{width: 20}} src={toAbsoluteUrl("/media/icons/female-kid.svg")}/> F</span>,
                    value: "f",
                    onSelected: "pink"
                },
            ]}
            name="student.genre"
            label="Genere"
        />
    </Col>
</Row>;
