/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Badge, Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {RULES, useUserRoles} from "../../../../../_the_law/_helpers/MustHaveRules";
import {intersection} from 'lodash';
import {FormattedMessage} from "react-intl";
import {useUIContext} from "../../UIContext";
import {useHistory} from "react-router-dom";
import {editResourceUrl} from "../../../Helpers";
import {GAME_LIST_URL} from "../../../PlayToBePlayer/constants";
import {useDispatch, useSelector} from "react-redux";
import {GAME_ROOMS_REDUX_SECTION_NAME} from "../../../PlayToBePlayer/_constants";
import {gameRoomSlice} from "../../../PlayToBePlayer/_redux/gameRoom/gameRoomSlice";
import {getIcon} from "../../../../setup/utils/icons";
import {useRecoilValue} from "recoil";
import {posAnalyticsAtom} from "../../../PlayToBePlayer/pages/GameRoom/charts/AnswerAnalytics";
import {useRecoilState, useSetRecoilState} from "recoil/dist";

const EditButton = ({editResource, row}) => {

    const permitted = true;

    return <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">
            <FormattedMessage id="PTBP.PRODUCTS_PAGE.TABLE.ACTIONS.EDIT"/>
        </Tooltip>}
    >
        <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => permitted && editResource(row.id)}
        >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
        </a>
    </OverlayTrigger>
}

const GameStateButton = ({handleClick, enabled = false}) => {
    return <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">
            {
                enabled ? <FormattedMessage id="PTBP.COMMON.ACTIONS.ABILITATION" defaultMessage="Pausa"/> :
                    <FormattedMessage id="PTBP.COMMON.ACTIONS.ABILITATION" defaultMessage="Avvia"/>
            }
        </Tooltip>}
    >
        <Button
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={handleClick}
        >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                  src={toAbsoluteUrl(enabled ? getIcon.from.Media('Pause') : getIcon.from.Media('Play'))}
              />
            </span>
        </Button>
    </OverlayTrigger>
}

const ChartButton = ({handleClick}) => {

    const setPos = useSetRecoilState(posAnalyticsAtom);

    return <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">
            <FormattedMessage id="PTBP.COMMON.ACTIONS.ABILITATION" defaultMessage="Analytic"/>
        </Tooltip>}
    >
        <Button
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={handleClick}
        >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                  src={toAbsoluteUrl(getIcon.from.Shopping('Chart-bar2'))}
              />
            </span>
        </Button>
    </OverlayTrigger>
}

const AbilitationButton = ({handleClick}) => {

    const newPlayers = useSelector(state => state?.[GAME_ROOMS_REDUX_SECTION_NAME].newPlayers);


    return <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">
            <FormattedMessage id="PTBP.COMMON.ACTIONS.ABILITATION" defaultMessage="Abilita/Disabilita"/>
        </Tooltip>}
    >
        <Button
            className="btn btn-light btn-hover-primary btn-sm mx-3"
            onClick={handleClick}
        >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}
              />
            </span>
            {
                newPlayers > 0 && <Badge variant="danger">{newPlayers}</Badge>
            }
        </Button>
    </OverlayTrigger>
}

const DeleteButton = ({deleteSingleResource, row}) => {

    const roles = useUserRoles();

    const permitted = true;
    return <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">
            <FormattedMessage id="PTBP.PRODUCTS_PAGE.TABLE.ACTIONS.DELETE"/>
        </Tooltip>}
    >
        <a
            className={"btn btn-icon btn-light btn-hover-danger btn-sm"}
            onClick={() => permitted && deleteSingleResource(row.id)}
        >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
            </span>
        </a>
    </OverlayTrigger>
}

export const ActionsColumn = ({row}) => {
    const {editResource, deleteSingleResource} = useUIContext();


    return (
        (row.approval_status !== 'removing' &&
            <>
                <EditButton editResource={editResource} row={row}/>
                <> </>
                <DeleteButton row={row} deleteSingleResource={deleteSingleResource}/>

            </>) || null

    );
}

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
) => {
    return <ActionsColumn row={row}/>
};

const GameRoomActionsColumn = ({row,index}) => {
    const {editResource, deleteSingleResource, viewAnalytics} = useUIContext();

    const history = useHistory();

    const dispatch = useDispatch();


    return (
        (row.approval_status !== 'removing' &&
            <>
                <ChartButton handleClick={() => {
                    return history.push(`${editResourceUrl(GAME_LIST_URL, row.id)}/analytics`);
                }}/>
                <> </>
                <GameStateButton
                    handleClick={() => {
                        dispatch(gameRoomSlice.actions.changeGameState({
                            id: row.id,
                            enable: !row?.game_state ?? true
                        }))
                    }}
                    enabled={row?.game_state ?? false}
                />
                <> </>
                <AbilitationButton
                    handleClick={() => {
                        dispatch(gameRoomSlice.actions.newPlayerRead())
                        return history.push(`${editResourceUrl(GAME_LIST_URL, row.id)}/abilitation`);
                    }}
                />
                <> </>
                <EditButton editResource={editResource} row={row}/>
                <> </>
                <DeleteButton row={row} deleteSingleResource={deleteSingleResource}/>

            </>) || null

    );
}


export const GameRoomActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex
) => {
    return <GameRoomActionsColumn row={row} index={rowIndex}/>
};
