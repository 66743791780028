import {getIcon} from "../utils/icons";
import {
    ADMINISTRATION_URL,
    CONTENT_URL,
    GAME_ADMIN_URL,
    STUDENTS_ADMINISTRATION_URL
} from "../../modules/PlayToBePlayer/constants";
import {contentCategory, contentPost, gameRoom, groups, school, students, supervisor} from "./subMenus";
import {RULES, RULES_GROUPS} from "../../../_the_law/_helpers/MustHaveRules";

const icons = getIcon.from;
export const adminMenu = {
    icon: icons.Shopping("Settings"),
    title: "Amministrazione",
    to: ADMINISTRATION_URL,
    multiple: true,
    rules: RULES_GROUPS.CAN_MANAGE,
    submenus: [school, supervisor]
};
export const studentMenu = {
    icon: icons.Files("Group-folders"),
    title: "Studenti",
    to: STUDENTS_ADMINISTRATION_URL,
    multiple: true,
    rules: RULES_GROUPS.CAN_MANAGE.concat([RULES.SUPERVISOR]),
    submenus: [students, groups]
};
export const contentMenu = {
    icon: icons.Files("Group-folders"),
    title: "Contenuti",
    to: CONTENT_URL,
    multiple: true,
    rules: [RULES.ADMIN],
    submenus: [contentPost, contentCategory]
};
export const gameMenu = {
    icon: icons.Files("Group-folders"),
    title: "Gioco",
    to: GAME_ADMIN_URL,
    multiple: true,
    rules: RULES_GROUPS.CAN_MANAGE_GAME,
    submenus: [gameRoom]
};
