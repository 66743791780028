import {TableList} from "../../../../partials/Table";
import {FormattedMessage} from "react-intl";
import {sortCaret, toAbsoluteUrl} from "../../../../../../_metronic/_helpers";
import React from "react";
import * as columnFormatters from "../../../../CrudFormPage/table/column-formatters";
import {HighlightedTextSearch} from "../../../partials";
import SVG from "react-inlinesvg";
import {Badge} from "react-bootstrap";

export const StudentTable = () => <TableList columns={[
    {
        dataField: 'id',
        text: <FormattedMessage
            id="PTBP.COMMON.ID"
            defaultMessage="Id"
        />,
    },
    {
        dataField: 'student.first_name',
        text: <FormattedMessage
            id="PTBP.SCHOOL.NAME"
            defaultMessage="Nome"
        />,
        formatter: (val, row) => <HighlightedTextSearch
            text={`${row.student.last_name} ${row.student.first_name}`}
        />
    },
    {
        dataField: 'student.genre',
        text: <FormattedMessage
            id="PTBP.SCHOOL.GENRE"
            defaultMessage="GENERE"
        />,
        formatter: (val, row) => {
            return {
                m: <Badge variant={"primary"}><SVG style={{width: 20}} src={toAbsoluteUrl("/media/icons/male-kid.svg")}/> M</Badge>,
                f: <Badge style={{backgroundColor: "pink"}}><SVG style={{width: 20}} src={toAbsoluteUrl("/media/icons/female-kid.svg")}/> F</Badge>
            }[val]
        }
    },
    {
        dataField: "action",
        text: <FormattedMessage id="PTBP.PRODUCTS_PAGE.TABLE.COLUMNS.ACTIONS"/>,
        formatter: columnFormatters.ActionsColumnFormatter,
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    },
]}/>
