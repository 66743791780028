import {CONTENT_POST_LIST_URL} from "../../constants";
import React from "react";
import {CONTENT_POSTS_REDUX_SECTION_NAME} from "../../_constants";
import {TableList} from "../../../partials/Table";
import {Edit} from "../../../partials/Edit";
import {fields} from "./fields";
import {columns} from "./columns";
import {contentPostSlice} from "../../_redux/contentPost/contentPostSlice";

export default {
    storeSection: CONTENT_POSTS_REDUX_SECTION_NAME,
    actions: contentPostSlice.actions,
    base_url: CONTENT_POST_LIST_URL,
    listCmp: () => {
        return <TableList columns={columns}/>;
    },
    filterCmp: () => <></>,
    newCmp: Edit,
    editCmp: Edit,
    formProps: {
        initValues: {
            title: '',
            description: '',
            slug: '',
            category: '',
            cover: null
        },
        fields
    },
    alias: {
        newTitle: "Nuovo Post",
        editTitle: "Modifica Post",
        listTitle: "Lista Post"
    },
    filter_set: {
        sortField: "id",
    },
    cardActions: [],
    rules: {
        canCreate: []
    }

};
