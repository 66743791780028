import {Col, Row} from "react-bootstrap";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../../_metronic/_partials/controls";
import React from "react";

export const fields = <Row className="inputs-rows mt-5">
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="PTBP.SCHOOL.NAME"
                defaultMessage="Nome"
            />}
            component={Input}
            name="name"
            key="name"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.SCHOOL.REFERER.FIRST_NAME"
                defaultMessage="Nome Referente"
            />}
            component={Input}
            name="referer.first_name"
            key="referer.first_name"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.SCHOOL.REFERER.LAST_NAME"
                defaultMessage="Cognome Referente"
            />}
            component={Input}
            name="referer.last_name"
            key="referer.last_name"
        />
    </Col>
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="PTBP.SCHOOL.EMAIL"
                defaultMessage="Email"
            />}
            component={Input}
            name="email"
            key="email"
        />
    </Col>
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="PTBP.SCHOOL.PEC"
                defaultMessage="Indirizzo Pec"
            />}
            component={Input}
            name="info.pec_mail"
            key="info.pec_mail"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.SCHOOL.INFO.MEC_CODE"
                defaultMessage="Codice Meccanografico"
            />}
            component={Input}
            name="info.mechanographic_code"
            key="info.mechanographic_code"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.SCHOOL.INFO.SDI"
                defaultMessage="Codice Univoco (SDI)"
            />}
            component={Input}
            name="info.sdi_code"
            key="info.sdi_code"
        />
    </Col>
</Row>
