/* eslint-disable no-restricted-imports */
import React, {useEffect, useMemo} from "react";
import {Modal} from "react-bootstrap";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {useUIContext} from "../UIContext";
import {FormattedMessage} from "react-intl";

export function MultipleDeleteDialog({show, onHide}) {
    // Products UI Context
    const {actions, ...uiContext} = useUIContext();
    const uiProps = useMemo(() => {
        return {
            ids: uiContext.ids,
            setIds: uiContext.setIds,
            queryParams: uiContext.queryParams,
        };
    }, [uiContext]);

    // Products Redux state
    const dispatch = useDispatch();
    const {isLoading} = useSelector(
        (state) => ({isLoading: state[uiContext.storeSection].actionsLoading}),
        shallowEqual
    );

    // looking for loading/dispatch
    useEffect(() => {
    }, [isLoading, dispatch]);

    // if there weren't selected products we should close modal
    // useEffect(() => {
    //     if (!uiProps.ids || uiProps.ids.length === 0) {
    //         onHide();
    //     }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [uiProps.ids]);

    const deleteALl = () => {
        // server request for deleting product by seleted ids
        dispatch(actions.deleteResources(uiProps.ids)).then(() => {
            // refresh list after deletion
            dispatch(actions.fetchResources(uiProps.queryParams)).then(() => {
                // clear selections list
                uiProps.setIds([]);
                // closing delete modal
                onHide();
            });
        });
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {isLoading && <ModalProgressBar/>}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <FormattedMessage id="PTBP.COMMON.TITLE.RESOURCE_DELETE"/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoading && (
                    <span><FormattedMessage id="PTBP.COMMON.TITLE.MULTIPLE_DELETE_ASK"/></span>
                )}
                {isLoading && <span><FormattedMessage id="PTBP.COMMON.TITLE.DELETING_RESOURCES"/></span>}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        <FormattedMessage id="PTBP.COMMON.ACTIONS.ABORT"/>
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={deleteALl}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="PTBP.COMMON.ACTIONS.DELETE"/>
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
