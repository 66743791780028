import {Col, Row} from "react-bootstrap";
import {SchoolSelect} from "../../partials/SchoolSelect";
import {GenericSearch} from "../../partials/Filter/GenericSearch";
import React from "react";
import {SupervisorSelect} from "../../partials/SupervisorSelect";
import {StudentSelect} from "../../partials/StudentSelect";

export const filterFields = <Row>
    <Col md={3}>
        <StudentSelect
            emptyOption={<option value="">Tutti gli studenti</option>}
            submitOnChange={true}
            metaSection="groups"
        />
    </Col>
    <Col md={3}>
        <SupervisorSelect
            emptyOption={<option value="">Tutti i supervisori</option>}
            submitOnChange={true}
            metaSection="groups"
        />
    </Col>
    <Col>
        <GenericSearch/>
    </Col>
</Row>;
