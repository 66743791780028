import {Col, Row} from "react-bootstrap";
import {MustHaveRules, RULES} from "../../../../../../_the_law/_helpers/MustHaveRules";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../../../_metronic/_partials/controls";
import React from "react";
import {SupervisorSelect} from "../../partials/SupervisorSelect";
import {StudentsSelection} from "../../partials/StudentsSelection";


export const fields = <Row className="inputs-rows mt-5">
    <MustHaveRules rules={[RULES.ADMIN, RULES.SCHOOL]}>
        <Col md={12}>
            <SupervisorSelect metaSection="groups"/>
        </Col>
    </MustHaveRules>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.GROUP.NAME"
                defaultMessage="Nome"
            />}
            component={Input}
            name="name"
            key="name"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="PTBP.GROUP.DESCRIPTION"
                defaultMessage="Descrizione"
            />}
            component={Input}
            name="description"
            key="description"
        />
    </Col>
    <Col xs={12}>
        <StudentsSelection/>
    </Col>
</Row>;
