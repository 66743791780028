import {Col, Row} from "react-bootstrap";
import {SchoolSelect} from "../../partials/SchoolSelect";
import {GenericSearch} from "../../partials/Filter/GenericSearch";
import React from "react";
import {SupervisorSelect} from "../../partials/SupervisorSelect";
import {MustHaveRules, RULES} from "../../../../../../_the_law/_helpers/MustHaveRules";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../../_metronic/_helpers";
import {SwitchField} from "../../partials/Filter/fileds/Switch";

export const filterFields = <Row>
    <MustHaveRules rules={[RULES.ADMIN]}>
        <Col>
            <SchoolSelect
                emptyOption={<option value="">Tutte le scuole</option>}
                submitOnChange={true}
                metaSection="students"
            />
        </Col>
    </MustHaveRules>
    <MustHaveRules rules={[RULES.ADMIN, RULES.SCHOOL]}>
        <Col>
            <SupervisorSelect
                emptyOption={<option value="">Tutti i supervisori</option>}
                submitOnChange={true}
                metaSection="students"
            />
        </Col>
    </MustHaveRules>
    <Col xs={"auto"}>
        <SwitchField
            options={[
                {
                    label: <span>---</span>,
                    value: "",
                    onSelected: "#1BC5BD"
                },
                {
                    label: <span><SVG style={{width: 20}} src={toAbsoluteUrl("/media/icons/male-kid.svg")}/> M</span>,
                    value: "m",
                    onSelected: "#3699FF"
                },
                {
                    label: <span><SVG style={{width: 20}} src={toAbsoluteUrl("/media/icons/female-kid.svg")}/> F</span>,
                    value: "f",
                    onSelected: "pink"
                },
            ]}
            name="genre"
            label="Genere"
            submitOnChange
        />
    </Col>
    <Col>
        <GenericSearch/>
    </Col>
</Row>;
