import {STUDENTS_REDUX_SECTION_NAME} from "../../_constants";
import {STUDENT_LIST_URL} from "../../constants";
import {Filter} from "../../../partials/Filter";
import {Edit} from "../../../partials/Edit";
import {studentsSlice} from "../../_redux/students";
import {filterFields} from "./fields/filter";
import {fields} from "./fields/table";
import {StudentTable} from "./partials/Table";

export const studentsPageConfigs = {
    storeSection: STUDENTS_REDUX_SECTION_NAME,
    actions: studentsSlice.actions,
    base_url: STUDENT_LIST_URL,
    listCmp: StudentTable,
    filterCmp: Filter,
    filterProps: {
        initValues: {
            search: '',
            school: '',
            genre: '',
        },
        fields: filterFields
    },
    newCmp: Edit,
    editCmp: Edit,
    formProps: {
        initValues: {
            email: "",
            name: "",
            student: {
                first_name: "",
                last_name: "",
                genre: "",
                birth_date: ""
            },
            school: ""
        },
        fields: fields
    },
    alias: {
        newTitle: "Nuovo Studente",
        editTitle: "Modifica Studente",
        listTitle: "Lista Studenti"
    },
    filter_set: {
        sortField: "id",
    },
    cardActions: [],
    rules: {
        canCreate: []
    }

};
