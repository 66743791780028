import {NavLink} from "react-router-dom";
import React, {memo} from "react";
import {useMenuItemUrl} from "../../../../../_the_law/hooks";

export const AsideSubMenuListItem = memo(({url, label}) => {
    const getMenuItemActive = useMenuItemUrl(url);

    return <li
        className={`menu-item ${getMenuItemActive(url)}`}
        aria-haspopup="true"
    >
        <NavLink className="menu-link" to={url}>
            <i className="menu-bullet menu-bullet-dot">
                <span/>
            </i>
            <span className="menu-text">{label}</span>
        </NavLink>
    </li>
});
