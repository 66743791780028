import {useSelector} from "react-redux";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import {Select} from "../../../../../_metronic/_partials/controls";
import {AsOption} from "../../../partials/FormattedMessages";
import React from "react";

export const StudentSelect = ({emptyOption = null, submitOnChange = false, metaSection = 'groups'}) => {

    const students = useSelector(state => state?.[metaSection]?.meta?.students ?? [])

    return <Field
        label={<FormattedMessage
            id="PTBP.STUDENT.STUDENT_OWNER"
        />}
        name="student"
        key="student"
    >
        {
            ({field, form}) => {
                return <Select value={field.value ?? ''}
                               onChange={e => {
                                   form.setFieldValue(field.name, e.currentTarget.value);

                                   if (submitOnChange) {
                                       form.submitForm().then(r => {
                                       });
                                   }

                               }}>
                    {emptyOption || <AsOption>
                        <FormattedMessage
                            id="PTBP.STUDENT.SELECT_STUDENT"
                            defaultMessage="Seleziona studente"
                        />
                    </AsOption>}
                    {
                        students.map(el => <option value={el.id}>{el.name}</option>)
                    }
                </Select>;
            }
        }
    </Field>;
};
