import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {fill, range} from 'lodash';
import {useSelector} from "react-redux";
import {atom, useRecoilValue} from "recoil/dist";
import {Card} from "react-bootstrap";

const filler = fill(new Array(13), 0, 0, 13);
const initialValues = () => [[...filler], [...filler], [...filler]];


export const posAnalyticsAtom = atom({
    key: "posAnalytics",
    default: null
});

export const ApexChart = ({game_room}) => {

    const { listLoading: isLoading, actionsLoading} = useSelector(state => state?.game_rooms)

    const events_data = game_room?.events ?? [];

    useEffect(() => {
        const temp = initialValues();
        const relevant_events = events_data.filter(el => el.name === 'GameEnded').map(({event: {answerIndex, gameIndex}}) => {
            const gameInt = parseInt(gameIndex);
            temp[answerIndex][gameInt]++;
        });

        setEventFounded(temp);
        setSeries([
            {
                name: 'Risposta 1',
                data: temp[0]
            }, {
                name: 'Risposta 2',
                data: temp[1]
            }, {
                name: 'Risposta 3',
                data: temp[2]
            }
        ])
    }, [game_room])

    const [eventFounded, setEventFounded] = useState(initialValues());

    const [series, setSeries] = useState([
        {
            name: 'Risposta 1',
            data: eventFounded[0]
        }, {
            name: 'Risposta 2',
            data: eventFounded[1]
        }, {
            name: 'Risposta 3',
            data: eventFounded[2]
        }
    ]);


    const apex_data = {
        options: {
            chart: {
                type: 'bar',
                height: "100%",
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            xaxis: {
                type: "category",
                categories: range(1, 14).map(el => `Gioco ${el}`),
                labels: {
                    rotate: -90
                }
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            }
        },
    };

    return (
        <div id="chart">
            <Card>
                <Card.Header>
                    <h3>
                        Distribuzione delle risposte
                    </h3>
                </Card.Header>
                <Card.Body>
                    {
                        <Chart options={apex_data.options} series={series} type="bar"/>
                    }
                </Card.Body>
            </Card>
        </div>
    )
};
