import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import {getUserByToken} from "./authCrud";

export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    UserLoaded: "[Load User] Auth API",
    ReLogin: "[ReLogin] Action",
    NeedReLogin: "[NeedReLogin] Action",
};

const initialAuthState = {
    user: undefined,
    authToken: undefined,
    needReLogin: false,
    backToken: undefined,
};

export const reducer = persistReducer(
    {storage, key: "ptbp-auth", whitelist: ["user", "authToken", "backToken"]},
    (state = initialAuthState, action) => {
        switch (action.type) {

            case actionTypes.ReLogin: {
                const {authToken} = action.payload;

                return {...state, authToken};
            }

            case actionTypes.NeedReLogin: {
                return {...state, needReLogin: true}
            }

            case actionTypes.Login: {
                const {authToken, backToken = false} = action.payload;

                return {authToken, backToken: backToken ? state.authToken : undefined, user: undefined};
            }

            case actionTypes.Register: {
                const {authToken} = action.payload;

                return {authToken, user: undefined};
            }

            case actionTypes.Logout: {
                // TODO: Change this code. Actions in reducer aren't allowed.
                return initialAuthState;
            }

            case actionTypes.UserLoaded: {
                const {user} = action.payload;
                return {...state, user, needReLogin: false};
            }

            default:
                return state;
        }
    }
);

export const actions = {
    login: (authToken, backToken = false) => ({type: actionTypes.Login, payload: {authToken, backToken}}),
    re_login: authToken => ({type: actionTypes.ReLogin, payload: {authToken}}),
    need_re_login: (error) => ({type: actionTypes.NeedReLogin, payload: {error}}),
    register: authToken => ({
        type: actionTypes.Register,
        payload: {authToken}
    }),
    logout: () => ({type: actionTypes.Logout}),
    requestUser: user => ({type: actionTypes.UserRequested, payload: {user}}),
    fulfillUser: user => ({type: actionTypes.UserLoaded, payload: {user}})
};

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.ReLogin, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const {data: user} = yield getUserByToken();

        yield put(actions.fulfillUser(user));
    });
}
