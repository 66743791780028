import React from "react";
import {Field, Formik} from "formik";
import {useUIContext} from "../../CrudFormPage/UIContext";
import {Button, Col, Row} from "react-bootstrap";

export const Filter = (
    {
        initValues,
        fields
    }
) => {

    const {
        queryParams,
        setQueryParams,
    } = useUIContext();

    return <Formik onReset={(values,formikHelpers) => {
        formikHelpers.submitForm().then(r => {
        });
    }} enableReinitialize={true} initialValues={initValues} onSubmit={values => {
        setQueryParams({
            ...queryParams,
            filter: {
                ...queryParams.filter,
                ...values
            }
        })
    }}>
        <Row>
            <Col xs="auto">
                <Field name="test">
                    {
                        ({form}) => <Button variant={"light"} onClick={() => {
                            form.resetForm();
                        }} type="button">Pulisci</Button>
                    }
                </Field>
            </Col>
            <Col>
                {fields}
            </Col>
        </Row>
    </Formik>
}
