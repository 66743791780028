import Card from "react-bootstrap/Card";
import React, {useEffect} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useRoomPresences} from "../../../hooks";
import {forgetGameRoom, getGameRoom} from "../../../../../../redux/pusherStore";
import {MEMBERS_CHANGED} from "../../../../../../redux/rooms/reducer";
import {groupBy, map, sortBy} from 'lodash';
import {ParticipantStatusLine} from "./ParticipantList/StatusLine";
import {editResourceUrl} from "../../../../Helpers";
import {GAME_LIST_URL} from "../../../constants";
import {useHistory} from "react-router";

export function ParticipantGroupListPresences({group, presences = [], events, onVisitStudent}) {

    const events_groups = groupBy(events, el => el.student_id)


    return map(sortBy((group?.students ?? []), (a) => {
        const isPresent = !!presences?.[a.id] ?? false;
        return isPresent ? -1 : 0;
    }), el => {
        return <ListGroup.Item>
            <ParticipantStatusLine
                presences={presences}
                participant={el}
                events={events_groups?.[el.id] ?? []}
                onVisitStudent={onVisitStudent}
            />
        </ListGroup.Item>;
    })

}

export function ParticipantList({
                                    game_room, onNewPresence = () => {
    }
                                }) {

    const {
        last_event,
        me,
        presences
    } = useRoomPresences();

    useEffect(() => {
        if (last_event === 'pusher:member_added') {
            onNewPresence();
        }
    }, [last_event])

    const slug = game_room.slug;
    useEffect(() => {
        if (slug) {
            getGameRoom(slug);
            getGameRoom(slug, 'pusher:member_added', MEMBERS_CHANGED.type);
            getGameRoom(slug, 'pusher:member_removed', MEMBERS_CHANGED.type);
        }
        return () => slug && forgetGameRoom(slug)
    }, [slug])


    const {groups = [],events} = game_room;

    const history = useHistory();
    const onVisitStudent = (student_id) => history.push(`${editResourceUrl(GAME_LIST_URL, game_room.id)}/profiler/${student_id}`);


    return <Card>
        <Card.Header>
            <h3>
                Lista Partecipanti
            </h3>
        </Card.Header>
        <Card.Body>
            <>
                {
                    groups.map(el => <ListGroup>
                        <ListGroup.Item>
                            {el.name}
                        </ListGroup.Item>
                        <ListGroup.Item>Attivi: {Object.keys(presences)?.length ?? 0}</ListGroup.Item>
                        <ListGroup.Item>Eventi registrati: {events?.length ?? 0}</ListGroup.Item>
                        <PerfectScrollbar>
                            <p style={{maxHeight: '300px', position: 'relative'}} className="my-2">
                                <ParticipantGroupListPresences
                                    group={el}
                                    presences={presences}
                                    events={events}
                                    onVisitStudent={onVisitStudent}
                                />
                            </p>
                        </PerfectScrollbar>
                    </ListGroup>)
                }
            </>
        </Card.Body>
    </Card>;
}
