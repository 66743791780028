import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/layout";
import {HOME, PAGE_URL} from "./modules/PlayToBePlayer/constants";
import {ModuleContainer} from "./modules/partials/ModuleContainer";
import PlayToBePlayerDashboardDefinition from "./modules/PlayToBePlayer/pages/PlayToBePlayerDashboardDefinition";


const PlayToBePlayerDashboard = () => <ModuleContainer moduleDefinition={PlayToBePlayerDashboardDefinition}/>;

export default function BasePage() {
    // useEffect(() => {
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <Redirect exact from="/" to={HOME}/>
                <Route path={PAGE_URL} component={PlayToBePlayerDashboard}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
