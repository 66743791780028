import {MustHaveRules} from "../../../../../_the_law/_helpers/MustHaveRules";
import AsideMenuListItem from "./AsideMenuListItem";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";
import React from "react";

export const AsideMenuItem = ({rules = [], title, to, multiple = false, submenus = [], icon}) =>
    <MustHaveRules rules={rules}>
        <AsideMenuListItem
            icon={<SVG src={toAbsoluteUrl(icon)}/>}
            title={title}
            to={to}
            multiple={multiple}
            submenus={submenus}
        />
    </MustHaveRules>;
