import {Col, Row} from "react-bootstrap";
import {SchoolSelect} from "../../partials/SchoolSelect";
import {GenericSearch} from "../../partials/Filter/GenericSearch";
import React from "react";
import {MustHaveRules, RULES} from "../../../../../../_the_law/_helpers/MustHaveRules";

export const filterFields = <Row>
    <MustHaveRules rules={[RULES.ADMIN]}>
        <Col md={4}>
            <SchoolSelect
                emptyOption={<option value="">Tutte le scuole</option>}
                submitOnChange={true}
            />
        </Col>
    </MustHaveRules>
    <Col>
        <GenericSearch/>
    </Col>
</Row>;
