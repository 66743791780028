import React from "react";
import {PaginationLinks} from "./PaginationLinks";
import {PaginationToolbar} from "./PaginationToolbar";
import {FormattedMessage} from "react-intl";

const FormattedIntlTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        <FormattedMessage
            id="PTBP.BOOTSTRAP_TABLE.PAGINATION_TOTAL"
            values={{
                from,
                to,
                size
            }}
        />
  </span>
);

const PaginationView = ({paginationProps, isLoading}) =>
    <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationLinks paginationProps={paginationProps}/>
        <PaginationToolbar
            isLoading={isLoading}
            paginationProps={{
                ...paginationProps,
                paginationTotalRenderer: FormattedIntlTotal
            }}
        />
    </div>

export function Pagination({after = true, before = true, children, isLoading, paginationProps}) {
    return (
        <>
            {(before && <PaginationView isLoading={isLoading} paginationProps={paginationProps}/>) || null}
            {children}
            {(after && <PaginationView isLoading={isLoading} paginationProps={paginationProps}/>) || null}
        </>
    );
}
