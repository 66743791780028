import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {fill, filter, groupBy, intersection, map, max, mean, min, range, values} from 'lodash';
import {useSelector} from "react-redux";
import {atom} from "recoil/dist";
import {Card} from "react-bootstrap";
import moment from "moment";

const filler = fill(new Array(13), 0, 0, 13);
const initialValues = () => [...filler];


export const posAnalyticsAtom = atom({
    key: "posAnalytics",
    default: null
});

export const AnswerTimeAnalytics = ({game_room}) => {


    const {listLoading: isLoading, actionsLoading} = useSelector(state => state?.game_rooms)

    const events_data = game_room?.events ?? [];

    useEffect(() => {

        const collection = groupBy(
            events_data,
            el => el.event.gameIndex
        );
        const means = filter(values(map(
            collection,
            (
                el => {
                    return parseFloat(mean(map(
                        filter(groupBy(
                            el.filter(el => el.name === 'GameStarted' || el.name === 'GameEnded'),
                            el => el.event.sessionId
                        ), (el => {
                            return intersection(el.map(el => el.name), ['GameStarted', 'GameEnded']).length === 2;
                        })),
                        el => Math.abs(moment(el[0].event.timestamp).diff(moment(el[1].event.timestamp), 'seconds'))
                        )).toFixed(2)
                    )
                }
            )
        )));

        const mins = filter(values(map(
            collection,
            (
                el => {
                    const min1 = min(map(
                        filter(groupBy(
                            el.filter(el => el.name === 'GameStarted' || el.name === 'GameEnded'),
                            el => el.event.sessionId
                        ), (el => {
                            return intersection(el.map(el => el.name), ['GameStarted', 'GameEnded']).length === 2;
                        })),
                        el => {


                            return Math.abs(moment(el[0].event.timestamp).diff(moment(el[1].event.timestamp), 'seconds'));
                        }
                    ));


                    return parseFloat((min1 ?? 0).toFixed(2)
                    )
                }
            )
        )));


        const maxs = filter(values(map(
            collection,
            (
                el => {
                    const max1 = max(map(
                        filter(groupBy(
                            el.filter(el => el.name === 'GameStarted' || el.name === 'GameEnded'),
                            el => el.event.sessionId
                        ),(el => {
                            return intersection(el.map(el => el.name), ['GameStarted', 'GameEnded']).length === 2;
                        })),
                        el => Math.abs(moment(el[0].event.timestamp).diff(moment(el[1].event.timestamp), 'seconds'))
                    ));

                    return parseFloat((max1 ?? 0).toFixed(2)
                    )
                }
            )
        )));

        setSeries([
            {
                name: "Tempi medi",
                data: means
            },
            {
                name: "Tempo massimo",
                data: maxs
            },
            {
                name: "Tempo minimo",
                data: mins
            },
        ])
    }, [game_room]);

    const [series, setSeries] = useState([{
        name: "Tempi medi",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
    }]);


    var options = {
        series: [{
            name: "Tempi medi",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Tempi di risposta',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: range(1, 14).map(el => `Gioco ${el}`),
        }
    };

    const apex_data = {
        options: {
            chart: {
                type: 'line',
                height: 500,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            stroke: {
                curve: 'straight'
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            xaxis: {
                type: "numeric",
                categories: range(1, 14),
                labels: {
                    rotate: -90
                }
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            }
        },
    };

    return (
        <Card>
            <Card.Header>
                <h3>
                    Tempi di risposta per gioco
                </h3>
            </Card.Header>
            <Card.Body>
                {
                    <Chart options={options} series={series} type="line"/>
                }
            </Card.Body>
        </Card>
    )
};
