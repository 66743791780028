import {FormattedMessage} from "react-intl";
import {sortCaret} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "../../../CrudFormPage/table/column-formatters";
import React from "react";

export const columns = [
    {
        dataField: 'id',
        text: <FormattedMessage
            id="PTBP.COMMON.ID"
            defaultMessage="Id"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: 'title',
        text: <FormattedMessage
            id="PTBP.CONTENT_CATEGORY.TITLE"
            defaultMessage="Titolo"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: 'slug',
        text: <FormattedMessage
            id="PTBP.CONTENT_CATEGORY.SLUG"
            defaultMessage="Slug"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: "action",
        text: <FormattedMessage id="PTBP.PRODUCTS_PAGE.TABLE.COLUMNS.ACTIONS"/>,
        formatter: columnFormatters.ActionsColumnFormatter,
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    },
];
