import {useDispatch, useSelector} from "react-redux";
import {useUIContext} from "../../../../CrudFormPage/UIContext";
import React, {useEffect, useState} from "react";
import {getGameRoomEvents} from "../../../../../../redux/pusherStore";
import {Modal} from "react-bootstrap";
import {PROFILE_STUDENT} from "../../../../../../redux/rooms/reducer";
import {useRoomPresences} from "../../../hooks";
import {gameRoomSlice} from "../../../_redux/gameRoom/gameRoomSlice";
import ListGroup from "react-bootstrap/ListGroup";
import {filter, groupBy, map, mapKeys, mapValues} from 'lodash';
import {useHistory} from "react-router";
import Badge from "react-bootstrap/Badge";

export const themeQuestionsPoints = {
    bullismo: [
        [2, 3, 5],
        [2, 3, 5],
        [2, 5, 3],
        [5, 2, 3],
    ],
    ambiente: [
        [3, 2, 5],
        [3, 2, 5],
        [2, 3, 5],
        [5, 2, 3],
    ],
    integrazione: [
        [2, 5, 3],
        [5, 2, 3],
        [3, 2, 5],
        [2, 3, 5],
    ]
};

export const profilePoints = {
    assertivo: {
        label: "Assertivo, pro sociale",
        points: 16,
        answers: [2, 2, 2, 2]
    },
    evitante: {
        label: "Evitante",
        points: 81,
        answers: [3, 3, 3, 3]
    },
    aggressivo: {
        label: "Aggressivo",
        points: 625,
        answers: [5, 5, 5, 5]

    },
    assertivo_evitante_1: {
        label: "Assertivo/Evitante (25/75)",
        points: 54,
        answers: [2, 3, 3, 3]

    },
    assertivo_evitante_2: {
        label: "Assertivo/Evitante (50/50)",
        points: 36,
        answers: [2, 2, 3, 3]

    },
    assertivo_evitante_3: {
        label: "Assertivo/Evitante (75/25)",
        points: 24,
        answers: [2, 2, 2, 3]

    },
    evitante_aggressivo_1: {
        label: "Evitante/Aggressivo (25/75)",
        points: 135,
        answers: [5, 5, 5, 3]

    },
    evitante_aggressivo_2: {
        label: "Evitante/Aggressivo (50/50)",
        points: 225,
        answers: [5, 5, 3, 3]

    },
    evitante_aggressivo_3: {
        label: "Evitante/Aggressivo (25/75)",
        points: 375,
        answers: [5, 3, 3, 3]

    },
    assertivo_aggressivo_1: {
        label: "Assertivo/Aggressivo (25/75)",
        points: 250,
        answers: [2, 5, 5, 5]

    },
    assertivo_aggressivo_2: {
        label: "Assertivo/Aggressivo (50/50)",
        points: 100,
        answers: [2, 2, 5, 5]

    },
    assertivo_aggressivo_3: {
        label: "Assertivo/Aggressivo (75/25)",
        points: 40,
        answers: [2, 2, 2, 5]

    },
};


const GAME_STARTED_EVENT = 'GameStarted';
const GAME_ENDED_EVENT = 'GameEnded';
const GAME_INSTANCE_CREATED = 'GameInstanceCreated';

export const Profiler = ({
                             handleClose = null,
                             match
                         }) => {

    const {resourceForEdit = false} = useSelector(state => state?.game_rooms);
    const {profiling = false} = useSelector(state => state?.rooms);

    const history = useHistory();

    const {
        isPresent
    } = useRoomPresences()

    const id = match?.params?.id;
    const student_id = match?.params?.student;

    console.log(match)

    const {back} = useUIContext();


    const [slug, setSlug] = useState(null);

    const dispatch = useDispatch();

    const roomData = useSelector(state => state?.rooms ?? {});

    const last_event = roomData?.event ?? '';
    const last_generated_event = roomData?.generatedEvent ?? '';

    let game_room_events = [];


    (profiling?.groups ?? []).map(group => {
        (group?.game_rooms ?? []).map(room => {
            if (room.id === resourceForEdit.id) {
                game_room_events = room.events;
            }
        })
    });


    function initResource() {
        dispatch(PROFILE_STUDENT({game_room: id, student: student_id}));
        dispatch(gameRoomSlice.actions.fetchResource(id));
    }

    useEffect(() => {
        initResource();
    }, [id, student_id])

    useEffect(() => {
        if (resourceForEdit) {
            setSlug(resourceForEdit.slug);
        }

    }, [resourceForEdit]);

    useEffect(() => {
        if (slug) {
            getGameRoomEvents(slug, "App\\Events\\GameRoomEventGenerated");
        }
    }, [slug])

    useEffect(() => {
        if (last_event === "App\\Events\\GameRoomEventGenerated" && (last_generated_event === GAME_STARTED_EVENT || last_generated_event === 'GameEnded')) {
            initResource();
        }
    }, [last_event, last_generated_event])

    const onlyEndedEvents = el => el.name === GAME_ENDED_EVENT;
    const onlyStartedEvents = el => el.name === GAME_STARTED_EVENT;
    const onlyInstanceCreatedEvents = el => el.name === GAME_INSTANCE_CREATED;


    const answer_per_session = groupBy(game_room_events.filter(onlyEndedEvents), el => {
        console.log({el}, el.event.sessiondId)
        return el.event.sessionId;
    });

    const answers = map(answer_per_session, el => {
        return mapKeys(el, el => el?.event?.gameIndex)
    });

    console.log({answer_per_session, answers});


    const answers_pos = {
        bullismo: {
            points: [0, 1, 2, 3],
            label: "Bullismo"
        },
        ambiente: {
            points: [4, 5, 6, 7],
            label: "Ambiente"
        },
        integrazione: {
            points: [8, 9, 10, 11],
            label: "Integrazione"
        },
    }

    const points = map(answers, answers_session => mapValues(answers_pos, (config, theme) => {
        const them_answers_pos = config.points
        return {
            total: map(
                them_answers_pos,
                (question_number, question_index) => {
                    const event_answer = answers_session?.[question_number];
                    if (event_answer) {
                        const answerIndex = event_answer.event.answerIndex;

                        return themeQuestionsPoints[theme][question_index][answerIndex];
                    }
                    return 1;
                }
            ).reduce((points, answer) => points * (answer ?? 1), 1),
            ...map(
                them_answers_pos,
                (question_number, question_index) => {
                    const event_answer = answers_session?.[question_number];
                    if (event_answer) {
                        return event_answer.event.answerIndex;
                    }
                    return -1;
                }
            )
        };
    }))

    console.log(points);

    return <Modal dialogClassName="xl-modal" show={!!profiling} size="lg" onHide={history.goBack}>
        <Modal.Header>Profilo {profiling?.nickname}</Modal.Header>
        <Modal.Body>
            <ListGroup>
                <ListGroup.Item>Dati Game Room</ListGroup.Item>
                <ListGroup.Item>Eventi Registrati: {game_room_events.length}</ListGroup.Item>
                <ListGroup.Item>
                    Numero di partite Avviate: {game_room_events.filter(onlyInstanceCreatedEvents).length}
                </ListGroup.Item>
                <ListGroup.Item>
                    Numbero di stage avviati: {game_room_events.filter(onlyStartedEvents).length}
                </ListGroup.Item>
                <ListGroup.Item>
                    Numbero di stage conclusi: {game_room_events.filter(onlyEndedEvents).length}
                </ListGroup.Item>
            </ListGroup>
            <hr/>
            <ListGroup>
                <ListGroup.Item>Punteggi per sessione di gioco</ListGroup.Item>
            </ListGroup>
            {
                points.map((points_group, index) => {
                    return <>
                        <hr/>
                        <ListGroup>
                            <ListGroup.Item>Session {index+1}</ListGroup.Item>
                            {
                                map(points_group, (point, theme) => {

                                    const profile = filter(profilePoints, el => el.points === point.total)?.[0] ?? {label: 'Non elaborato'};

                                    delete point.total;

                                    console.log(point);

                                    return <ListGroup.Item>
                                        {answers_pos[theme].label}: <>
                                        {
                                            map(point, el => <Badge variant="primary" className="mx-1">{["A","B","C"][el]}</Badge>)
                                        }
                                        </> <>
                                        <Badge variant={"success"}>{profile.label}</Badge>
                                        </>
                                    </ListGroup.Item>;
                                })
                            }
                        </ListGroup>
                    </>
                })
            }
        </Modal.Body>
    </Modal>
}
