import {useAuthToken} from "../../../../../_the_law/hooks";
import React, {Fragment, useEffect, useState} from "react";
import {initPusher} from "../../../../../redux/pusherStore";
import {useRecoilState} from "recoil";
import {posAnalyticsAtom} from "./charts/AnswerAnalytics";
import {TableList} from "../../../partials/Table";
import {columns} from "./columns";

export const GameRoomTable = () => {
    const authToken = useAuthToken();

    useEffect(() => {
        initPusher(authToken);
    }, [])

    const [posAct, setPosAct] = useRecoilState(posAnalyticsAtom);


    const [student, setStudent] = useState(null);

    return <Fragment>
        <TableList columns={columns}/>

    </Fragment>;
};
