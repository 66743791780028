import React, {Fragment, useEffect, useRef, useState} from "react";
import {Button, Image} from "react-bootstrap";
import holder from 'holderjs';

export const ImageHolderViewer = ({handleClick, image}) => <Image fluid onClick={handleClick} className="cursor-pointer"
                                                                  src={image}/>
export const VideoHolderViewer = ({handleClick, image}) => {

    const [vNum, setVNum] = useState(0);

    useEffect(() => setVNum(vNum + 1), [image]);

    return <div id={`video-${vNum}`} style={{position: "relative", width: '100%'}}>
        <video style={{position: "relative", width: '100%'}} controls>
            <source src={image}/>
            Your browser does not support HTML5 video.
        </video>
        <Button onClick={handleClick} style={{position: 'absolute', top: 5, left: 5}} size={"sm"}>Cambia</Button>
    </div>
}

export const ImageHolder = ({handleImage, imageSrc = null, viewer = ImageHolderViewer, type = "image"}) => {
    const [image, setImage] = useState(imageSrc);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setImage(imageSrc)
    }, [imageSrc])

    useEffect(() => {
        holder.run("")
    }, [loading]);


    const ref = useRef();


    const readFileAsImage = function () {

        if (ref.current.files && ref.current.files[0]) {
            handleImage(ref.current.files[0])

            const FR = new FileReader();

            FR.addEventListener("load", function (e) {
                setImage(e.target.result)
            });

            FR.readAsDataURL(ref.current.files[0]);
        }

    };

    const readFileAsVideo = (evt) => {
        let reader;

        const file = ref.current.files[0];
        handleImage(ref.current.files[0])

        reader = window.URL || window.webKitURL;

        if (reader && reader.createObjectURL) {
            const url = reader.createObjectURL(file);
            setLoading(true);
            setTimeout(() => {

                setLoading(false);
                setImage(url);
            }, 500);
            // reader.revokeObjectURL(url);  //free up memory
            return;
        }

        if (!window.FileReader) {
            return;
        }

        reader = new window.FileReader();
        reader.onload = function (evt) {
            setImage(evt.target.result);
        };
        reader.readAsDataURL(file);
    }

    // const readFileAsVideo = () => {
    //     $source[0].src = URL.createObjectURL(ref.current.files[0]);
    //     $source.parent()[0].load();
    // }

    const handleChange = () => {
        if (type === 'image') {
            readFileAsImage();
        }

        if (type === 'video') {

        }
    }

    return <Fragment>
        <input onChange={type === 'image' ? readFileAsImage : readFileAsVideo} ref={ref} hidden type="file"/>
        {
            (
                image && !loading &&
                React.createElement(viewer, {
                    handleClick: (e) => {
                        e.preventDefault();
                        return ref.current.click();
                    },
                    image
                })
            ) ||
            <Image fluid onClick={() => ref.current.click()} className="cursor-pointer" key="cover-placeholder"
                   src="holder.js/100px250?text=Aggiungi cover"/>
        }
    </Fragment>
};
