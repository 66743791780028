import {
    GROUP_LIST_URL,
    SCHOOLS_LIST_URL,
    STUDENT_LIST_URL,
    SUPERVISOR_LIST_URL
} from "../../modules/PlayToBePlayer/constants";
import {RULES, RULES_GROUPS} from "../../../_the_law/_helpers/MustHaveRules";
import {CONTENT_CATEGORY_LIST_URL, CONTENT_POST_LIST_URL, GAME_LIST_URL} from "../../modules/PlayToBePlayer/constants";

export const school = {
    url: SCHOOLS_LIST_URL,
    label: "PTBP.GENERAL.SCHOOLS",
    rules: [RULES.ADMIN]
};

export const contentCategory = {
    url: CONTENT_CATEGORY_LIST_URL,
    label: "PTBP.GENERAL.CONTENT_CATEGORIES",
    rules: [RULES.ADMIN]
};

export const contentPost = {
    url: CONTENT_POST_LIST_URL,
    label: "PTBP.GENERAL.CONTENT_POSTS",
    rules: [RULES.ADMIN]
};

export const gameRoom = {
    url: GAME_LIST_URL,
    label: "PTBP.GENERAL.GAME_ROOMS",
    rules: RULES_GROUPS.CAN_MANAGE_GAME
};

export const supervisor = {
    url: SUPERVISOR_LIST_URL,
    label: "PTBP.GENERAL.SUPERVISORS",
    rules: [RULES.ADMIN, RULES.SCHOOL]
};

export const students = {
    url: STUDENT_LIST_URL,
    label: "PTBP.GENERAL.STUDENTS",
    rules: [RULES.ADMIN, RULES.SCHOOL, RULES.SUPERVISOR]
};

export const groups = {
    url: GROUP_LIST_URL,
    label: "PTBP.GENERAL.GROUPS",
    rules: [RULES.ADMIN, RULES.SCHOOL, RULES.SUPERVISOR]
};
