import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux";
import {
    categorySlice,
    customersSlice,
    managersSlice,
    pricingListSlice,
    producersSlice,
    trademarkSlice,
    visitSlice
} from "../app/modules/Gilda/_redux";
import {ownersSlice} from "../app/modules/Gilda/_redux/owners/ownersSlice";
import {
    CONTENT_CATEGORIES_REDUX_SECTION_NAME,
    CONTENT_POSTS_REDUX_SECTION_NAME,
    GAME_ROOMS_REDUX_SECTION_NAME,
    GROUPS_REDUX_SECTION_NAME,
    SCHOOLS_REDUX_SECTION_NAME,
    STUDENTS_REDUX_SECTION_NAME,
    SUPERVISORS_REDUX_SECTION_NAME
} from "../app/modules/PlayToBePlayer/_constants";
import {groupSlice, schoolSlice, supervisorsSlice} from "../app/modules/PlayToBePlayer/_redux";
import {studentsSlice} from "../app/modules/PlayToBePlayer/_redux/students";
import {contentCategorySlice} from "../app/modules/PlayToBePlayer/_redux/contentCategory/contentCategorySlice";
import {contentPostSlice} from "../app/modules/PlayToBePlayer/_redux/contentPost/contentPostSlice";
import {gameRoomSlice} from "../app/modules/PlayToBePlayer/_redux/gameRoom/gameRoomSlice";
import {roomReducer} from "./rooms/reducer";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    customers: customersSlice.reducer,
    producers: producersSlice.reducer,
    brands: producersSlice.reducer,
    owners: ownersSlice.reducer,
    managers: managersSlice.reducer,
    pricingLists: pricingListSlice.reducer,
    trademarks: trademarkSlice.slice.reducer,
    categories: categorySlice.slice.reducer,
    visit: visitSlice.slice.reducer,
    [SCHOOLS_REDUX_SECTION_NAME]: schoolSlice.slice.reducer,
    [SUPERVISORS_REDUX_SECTION_NAME]: supervisorsSlice.slice.reducer,
    [STUDENTS_REDUX_SECTION_NAME]: studentsSlice.slice.reducer,
    [GROUPS_REDUX_SECTION_NAME]: groupSlice.slice.reducer,
    [CONTENT_CATEGORIES_REDUX_SECTION_NAME]: contentCategorySlice.slice.reducer,
    [CONTENT_POSTS_REDUX_SECTION_NAME]: contentPostSlice.slice.reducer,
    [GAME_ROOMS_REDUX_SECTION_NAME]: gameRoomSlice.slice.reducer,

    rooms: roomReducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
