import React, {memo} from "react";
import {useSelector} from "react-redux";
import {intersection} from 'lodash';

export const RULES = {
    ADMIN: 1,
    SCHOOL: 2,
    SUPERVISOR: 3,
    STUDENT: 4,
    EDITOR: 3,
    PRICER: 4,
    MANAGER: 5,
}

export const RULES_GROUPS = {
    CAN_ADMIN: [RULES.ADMIN],
    CAN_MANAGE: [RULES.ADMIN, RULES.SCHOOL],
    CAN_DEPOSIT: [RULES.DEPOSITOR],
    CAN_MANAGE_GAME: [RULES.ADMIN, RULES.SCHOOL, RULES.SUPERVISOR]
}

export const useUserRoles = () => {
    const user = useSelector(state => state.auth.user);

    const {roles} = user;

    return {
        user,
        roles,
        hasRole(role) {
            return intersection(roles, [role]).length > 0
        },
        hasRoles(roles_check) {
            return intersection(roles, roles_check).length > 0;
        }
    };
}

export const MustHaveRules = ({children, rules = [], alternative = null, alternativeProps = null, ...others}) => {
    const {hasRoles} = useUserRoles();
    if (rules.length <= 0) {
        return children;
    } else {
        return (hasRoles(rules) && children) ||
            alternative ||
            (alternativeProps && React.Children.map(children, el => React.cloneElement(el, {
                ...others,
                ...alternativeProps
            }))) ||
            null;
    }

}

export const MustBeAdmin = (props) => <MustHaveRules {...props} rules={[RULES.ADMIN]}/>

export const EditableForRules = memo(({children, rules}) => {
        return <MustHaveRules
            rules={rules}
            alternativeProps={{disabled: true}}
        >
            {children}
        </MustHaveRules>
    }
)
export const EditableForPricer = memo(({children}) => {
        return <EditableForRules rules={[RULES.ADMIN, RULES.DEPOSITOR, RULES.EDITOR]}>
            {children}
        </EditableForRules>
    }
)
export const NotEditableField = memo(({children}) => {
    return <EditableForRules
        rules={[RULES.ADMIN, RULES.DEPOSITOR]}
    >
        {children}
    </EditableForRules>
});

export const EditableField = memo(({children}) => {
    return <EditableForRules
        rules={[RULES.ADMIN, RULES.DEPOSITOR, RULES.EDITOR, RULES.MANAGER]}
    >
        {children}
    </EditableForRules>
});
