import {InputGroup} from "react-bootstrap";
import {Field} from "formik";
import {DebounceInput} from "react-debounce-input";
import React from "react";

export function GenericSearch() {
    return <InputGroup>
        <InputGroup.Prepend>
            <InputGroup.Text>Cerca</InputGroup.Text>
        </InputGroup.Prepend>
        <Field name="search">
            {
                (props) => {

                    const {form, field} = props;

                    return <DebounceInput
                        type="text"
                        className="form-control"
                        {...props}
                        minLength={3}
                        debounceTimeout={500}
                        value={field.value}
                        onChange={e => {
                            form.setFieldValue(field.name, e.target.value);

                            form.submitForm().then(value => {
                            });
                        }}/>;
                }
            }
        </Field>
    </InputGroup>;
}
