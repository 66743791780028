import {SCHOOLS_LIST_URL} from "../../constants";
import React from "react";
import {SCHOOLS_REDUX_SECTION_NAME} from "../../_constants";
import {schoolSlice} from "../../_redux";
import {TableList} from "../../../partials/Table";
import {Edit} from "../../../partials/Edit";
import {fields} from "./fields";
import {Filter} from "../../../partials/Filter";
import {filterFields} from "./filter";
import {columns} from "./columns";

export default {
    storeSection: SCHOOLS_REDUX_SECTION_NAME,
    actions: schoolSlice.actions,
    base_url: SCHOOLS_LIST_URL,
    listCmp: () => {
        return <TableList columns={columns}/>;
    },
    filterCmp: Filter,
    filterProps: {
        initValues: {
            search: '',
            school: '',
        },
        fields: filterFields
    },
    newCmp: Edit,
    editCmp: Edit,
    formProps: {
        initValues: {
            name: '',
            referer: {
                first_name: '',
                last_name: ""
            },
            email: "",
            info: {
                mechanographic_code: "",
                pec_mail: "",
                sdi_code: ""
            }
        },
        fields
    },
    alias: {
        newTitle: "Nuova Scuola",
        editTitle: "Modifica Scuola",
        listTitle: "Lista Scuole"
    },
    filter_set: {
        sortField: "id",
    },
    cardActions: [],
    rules: {
        canCreate: []
    }

};
