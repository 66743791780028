import {
    CONTENT_CATEGORY_LIST_URL,
    CONTENT_POST_LIST_URL,
    GAME_LIST_URL,
    GROUP_LIST_URL,
    PAGE_URL,
    SCHOOLS_LIST_URL,
    STUDENT_LIST_URL,
    SUPERVISOR_LIST_URL
} from "../constants";
import {Page} from "../../CrudFormPage/Page";
import React from "react";
import schoolPageConfigs from "./School/schoolPageConfigs";
import {RULES, RULES_GROUPS} from "../../../../_the_law/_helpers/MustHaveRules";
import supervisorPageConfig from "./Supervisor/supervisorPageConfig";
import {studentsPageConfigs} from "./Students";
import {groupsPageConfigs} from "./Groups";
import contentCategoryPageConfigs from "./ContentCategory/contentCategoryPageConfigs";
import contentPostPageConfigs from "./ContentPost/contentPostPageConfigs";
import gameRoomPageConfigs from "./GameRoom/gameRoomPageConfigs";
import {editResourceUrl} from "../../Helpers";
import {ParticipantModal} from "./GameRoom/partials/ParticipantModal";
import {GameRoomAnalytics} from "./GameRoom/Analytics";

export default {
    redirect: {
        from: PAGE_URL,
        to: STUDENT_LIST_URL,
    },
    routes: [
        {
            path: SCHOOLS_LIST_URL,
            component: (props) => <Page {...props} {...schoolPageConfigs}/>,
            rules: [RULES.ADMIN]
        },
        {
            path: SUPERVISOR_LIST_URL,
            component: (props) => <Page {...props} {...supervisorPageConfig}/>,
            rules: [RULES.ADMIN, RULES.SCHOOL]
        },
        {
            path: STUDENT_LIST_URL,
            component: (props) => <Page {...props} {...studentsPageConfigs}/>,
            rules: [RULES.ADMIN, RULES.SCHOOL, RULES.SUPERVISOR]
        },
        {
            path: GROUP_LIST_URL,
            component: (props) => <Page {...props} {...groupsPageConfigs}/>,
            rules: [RULES.ADMIN, RULES.SCHOOL, RULES.SUPERVISOR]
        },
        {
            path: CONTENT_CATEGORY_LIST_URL,
            component: (props) => <Page {...props} {...contentCategoryPageConfigs}/>,
            rules: [RULES.ADMIN]
        },
        {
            path: CONTENT_POST_LIST_URL,
            component: (props) => <Page {...props} {...contentPostPageConfigs}/>,
            rules: [RULES.ADMIN]
        },
        {
            path: GAME_LIST_URL,
            component: (props) => <Page
                {...props}
                {...gameRoomPageConfigs}
            />,
            rules: RULES_GROUPS.CAN_MANAGE_GAME
        },
    ]
};
