import React, {Fragment, useEffect} from "react";
import {useUIContext} from "../../../../CrudFormPage/UIContext";
import {useDispatch, useSelector} from "react-redux";
import {ListGroup} from "react-bootstrap";
import {SwitchAbilitation} from "./SwitchAbilitation";
import {forgetGameRoom, getGameRoom} from "../../../../../../redux/pusherStore";
import {MEMBERS_CHANGED} from "../../../../../../redux/rooms/reducer";
import {useRoomPresences} from "../../../hooks";


export const StudentLabelPresence = ({present, student}) => {
    return <span
        className={present ? 'text-success text-bold' : ''}
    >{student.name}</span>
}

function setNewPresences(channel, setPresences) {
    const newPresences = {
        ...channel.members.members
    };

    delete newPresences[channel.members.me.id];
    setPresences(newPresences)
}

export const ParticipantList = ({
                                    enabledStatus,
                                    allEnabled,
                                    resourceGroups,
                                    gameRoomId,
                                    disabled,
                                    slug,
                                    onNewPresence
                                }) => {
    const {
        presences,
        me,
        last_event
    } = useRoomPresences();

    delete presences[me.id];

    useEffect(() => {
        if (slug) {
            getGameRoom(slug);
            getGameRoom(slug, 'pusher:member_added', MEMBERS_CHANGED.type);
            getGameRoom(slug, 'pusher:member_removed', MEMBERS_CHANGED.type);
        }
        return () => slug && forgetGameRoom(slug)
    }, [slug])

    useEffect(() => {
        if (last_event === 'pusher:member_added') {
            onNewPresence();
        }
    }, [last_event])


    const {actions} = useUIContext();

    const dispatch = useDispatch();


    return <ListGroup variant="flush" className="list-group-root">
        <ListGroup.Item>Attivi: {Object.keys(presences).length}</ListGroup.Item>
        <ListGroup.Item><SwitchAbilitation
            handleChange={() => {
                dispatch(actions.changeAllParticipation({
                    id: gameRoomId,
                    enable: !allEnabled
                }))
            }}
            disabled={disabled} checked={allEnabled}
            label="Tutti i partecipanti"/></ListGroup.Item>
        <ListGroup>
            {
                resourceGroups.map(group => {
                    const groupEnabled = group.tags.indexOf(enabledStatus) >= 0;
                    return <Fragment>
                        <ListGroup.Item>
                            <SwitchAbilitation disabled={disabled}
                                               label={group.name}
                                               checked={groupEnabled}
                                               handleChange={() => {
                                                   dispatch(actions.changeGroupParticipation({
                                                       id: gameRoomId,
                                                       group_id: group.id,
                                                       enable: !groupEnabled
                                                   }))
                                               }}
                            />
                        </ListGroup.Item>
                        <ListGroup className="list-group-root" variant="flush">
                            {
                                group.students.map(student => {
                                    const studentEnabled = student.tags.indexOf(enabledStatus) >= 0;


                                    return <ListGroup.Item>
                                        <SwitchAbilitation
                                            disabled={disabled}
                                            label={<StudentLabelPresence student={student}
                                                                         present={presences?.[student.id]}/>}
                                            id={student.id}
                                            checked={studentEnabled}
                                            handleChange={() => {
                                                dispatch(actions.changeStudentParticipation({
                                                    id: gameRoomId,
                                                    student_id: student.id,
                                                    enable: !groupEnabled
                                                }))
                                            }}
                                        />
                                    </ListGroup.Item>;
                                })
                            }
                        </ListGroup>
                    </Fragment>
                })
            }
        </ListGroup>
    </ListGroup>;
}
