import {useUIContext} from "../../../CrudFormPage/UIContext";
import {useDispatch} from "react-redux";
import React, {useState} from "react";
import {Badge, Button, OverlayTrigger, Popover} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

export const ActivationButton = ({id, active = false}) => {

    const {actions, actionsLoading} = useUIContext();

    const dispatch = useDispatch();

    const [requestActivation, setRequestActivation] = useState(false);

    const handleActiveRequest = () => {
        dispatch(actions.setActive(id, !active))
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">
                <FormattedMessage
                    id="PTBP.SCHOOL.ACTIVATION_POP_TITLE"
                    defaultMessage="Vuoi cambiare lo stato di attivazione di questa scuola?"
                />
            </Popover.Title>
            <Popover.Content>
                <Button type="button" onClick={handleActiveRequest}>Conferma</Button>
            </Popover.Content>
        </Popover>
    );


    return <OverlayTrigger overlay={popover} trigger={"click"}>
        <Badge className="cursor-pointer" disabled={actionsLoading} size={"sm"}
               variant={active ? "success" : "warning"}>
            {
                active ? <FormattedMessage
                    id="PTBP.TAGS.ACTIVE"
                    defaultMessage="Attivo"
                /> : <FormattedMessage
                    id="PTBP.TAGS.TO_ACTIVE"
                    defaultMessage="Non Attivo"
                />
            }
        </Badge>
    </OverlayTrigger>
}

export const TagsFormatter = (tags, row) => tags && tags.map(el => ({
    "status-to-active": <ActivationButton id={row['id']} active={false}/>,
    "status-active": <ActivationButton id={row['id']} active={true}/>
}[el]) ?? <Badge variant={"light"}>{el}</Badge>);
