import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Modal, ProgressBar, Row} from "react-bootstrap";
import {useUIContext} from "../../CrudFormPage/UIContext";
import {Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {get, map} from "lodash";

const CleanEdit = ({onHide, title, uploadProgress = 0, uploading = false, onSubmit, initialValues, fields, errors, isLoading = false}) => {

    return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
        {
            props => <Form>
                <Modal size="lg" show onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
                    <div>
                        <ProgressBar animated now={uploadProgress} variant={"success"} hidden={!uploading}/>
                    </div>
                    <Modal.Header>

                        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
                        <Row>
                            <Col>
                                <Button disabled={isLoading} type={"button"} onClick={onHide}>Annulla</Button>
                            </Col>
                            <Col>
                                <Button disabled={isLoading} type={"submit"} onClick={props.submitForm}>Salva</Button>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            errors &&
                            <Alert variant={"danger"}>
                                {map(errors, error => <div>{error[0]}</div>)}
                            </Alert>
                        }
                        {fields}
                        {
                            errors &&
                            <Alert variant={"danger"}>
                                {map(errors, error => <div>{error[0]}</div>)}
                            </Alert>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col>
                                <Button disabled={isLoading} type={"button"} onClick={onHide}>Annulla</Button>
                            </Col>
                            <Col>
                                <Button disabled={isLoading} type={"submit"} onClick={props.submitForm}>Salva</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </Form>
        }
    </Formik>
}


export const Edit = ({
                         match: {
                             params: {id},
                         },
                     }) => {

    const {alias, actions, back, storeSection, formProps: {initValues, fields}} = useUIContext();
    const {
        resourceForEdit, isLoading, actionsLoading,
        mediaProgress,
        mediaUploading
    } = useSelector(state => state[storeSection])

    const dispatch = useDispatch();

    const [alertError, setAlertError] = useState(null);


    useEffect(() => {
        dispatch(actions.fetchResource(id))
    }, [id])

    const getResponse = response => {
        if (get(response, 'type', `${storeSection}/endCall`) === `${storeSection}/catchError`) {
            if (response.payload.error.response.status === 403) {
                setAlertError([[response.payload.error.response.data.message]]);
            } else {
                setAlertError(response.payload.error.response.data.errors);
            }
        } else {
            back();
        }
    };

    const handleSubmit = (values, formikHelpers) => {
        if (id) {
            dispatch(actions.updateResource(values)).then(getResponse);
        } else {
            dispatch(actions.createResource(values)).then(getResponse);
        }
    };

    const getInitialResource = () => {
        return {
            ...initValues,
            ...resourceForEdit
        };
    }

    return <CleanEdit
        uploading={mediaUploading}
        uploadProgress={mediaProgress}
        title={(resourceForEdit?.id && alias.editTitle) || alias.newTitle}
        onHide={back}
        onSubmit={handleSubmit}
        initialValues={getInitialResource()}
        fields={<fieldset disabled={isLoading || actionsLoading}>
            {fields}
        </fieldset>}
        errors={alertError}
        isLoading={isLoading || actionsLoading}
    />;
}
